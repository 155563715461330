import React from "react";
import { TabScreen, Tabs, Tab } from "react-tabs-scrollable";
import PlanTabData from "./PlanTabData";
import planOne from "../../assets/images/plan-two.jpg";
import planTwo from "../../assets/images/plan-one.jpg";
import beverageOfferring from "../../assets/images/beverage-offering.jpg";
import activityOne from "../../assets/images/activity-advanture-one.jpg";
import activityTwo from "../../assets/images/activity-advanture-two.jpg";
import akiraSpaOne from "../../assets/images/akira-spa-one.jpg";
import akiraSpaTwo from "../../assets/images/akira-spa-two.jpg";
import arrivaDepartureOne from "../../assets/images/arrival-departure-one.jpg";
import coverOne from "../../assets/images/dining-experiene-cover.jpg";
import coverTwo from "../../assets/images/beverage-cover.jpg";
import coverThree from "../../assets/images/activities-advantures-cover.jpg";
import coverFour from "../../assets/images/akira-spa-tab-cover.jpg";
import coverFive from "../../assets/images/arrival-departure-cover.jpg";
import tabOneIcon from "../../assets/images/spoon-fork-brown.svg";
import tabTwoIcon from "../../assets/images/beverages.svg";
import tabThreeIcon from "../../assets/images/adventures.svg";
import tabFourIcon from "../../assets/images/spa-wellness.svg";
import tabFiveIcon from "../../assets/images/airplane.svg";
import Image from "../../components/Image";

function PlansTabView({ pageData = [] }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

 
const allpageData = pageData && pageData[0]?.subSectionData;

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
         plantabsdata={allpageData}
      >
        {pageData
          ?.filter((item) => item.section_code === "TABS")[0]
          ?.subSectionData.map((item, index) => {
            const icon = item.elements.filter(
              (item) => item.field_title_code === "ICON"
            )[0]?.data;
            const tabName = item.elements.filter(
              (item) => item.field_title_code === "TABNAME"
            )[0]?.data;

            return (
              <Tab
                key={index}
                className={`item ${
                  activeTab === index
                    ? "active text-uppercase d-flex align-items-center"
                    : "text-uppercase d-flex align-items-center"
                }`}
              >
                <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
                  <Image src={icon} alt="" title="" />
                </span>
                <span className="tab-name">{tabName}</span>
              </Tab>
            );
          })}
      </Tabs>

      {pageData
        ?.filter((item) => item.section_code === "TABS")[0]
        ?.subSectionData.map((item, index) => {
          const coverImage = item.elements.filter(
            (item) => item.field_title_code === "IMAGE"
          )[0]?.data;

          const tabImageAltTag = item.elements.filter(
            (item) => item.field_title_code === "IMAGE"
          )[0]?.img_alt_tag;

          const brochureLink = item.elements.filter(
            (item) => item.field_title_code === "BROCHURE"
          )[0]?.data;

          const tabHeading = item.elements.filter(
            (item) => item.field_title_code === "HEADING"
          )[0]?.data;

          const cardDat = item.children?.map((item) => item.elements);
          return (
            <TabScreen key={index} index={index} activeTab={activeTab}>
              <PlanTabData
                tabCoverImg={coverImage}
                tabHeading={tabHeading}
                downloadBrochureLink={brochureLink}
                btnTextName="Download Brochure"
                planCardData={cardDat}
                tabImageAltTag={tabImageAltTag}

                // planMeinDes={data.planMeinDes}
              />
            </TabScreen>
          );
        })}
    </>
  );
}

export default PlansTabView;
