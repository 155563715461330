import React,{useState,useEffect} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import { Link } from "react-router-dom";
import Glimpses from "../../components/Glimpses";

import { getAllVillas, getPageData1,getPageData } from "../../services/dataServices";
import { useQuery } from "react-query";
import Helmet from "../../components/Helmet";
import AllVillas from "./AllVillas";

import { useContext } from "react";
import { MyContext } from "../../Contex/LoadingContex";
import BannerImage from "../../components/BannerImage";
function OurVillas({isPreview,userId,sectionId,slugOfGallery,slugOfOurVillas}) {
  // const AllVillas = useQuery("villalist", getAllVillas);
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  const pageCode = "RVILLAS";

  const pagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview,userId,sectionId], () =>
    getPageData1(pageCode,isPreview,userId,sectionId)
  );
  const pagessubSectionData = useQuery(["pagessubSectionData", pageCode,isPreview,userId,sectionId], () =>
    getPageData(pageCode,isPreview,userId,sectionId)
  );

  const {isLoading,isSuccess}=pagesSectionData

 
  const sectionData = pagesSectionData?.data?.sectionData;

  const bannerImageDataObjects = sectionData?.find(item => item.section_code === "VILLAS" && item.field_label_code === "BANNERIMAGE");

  // Extract psdd_data values from filtered objects
  const bannerImageDataValues = bannerImageDataObjects?.psdd_data;
 
  const SubSectionData = pagessubSectionData?.data?.data[0]?.section_details[0]?.subSectionData[0].elements;

  const bannerImage =bannerImageDataValues?bannerImageDataValues : bannerCover;
 
  const Image1 = SubSectionData?.find(item => item.field_title_code=== "IMAGE5").data;
  const Image2 = SubSectionData?.find(item => item.field_title_code=== "IMAGE2").data;
  const Image3 = SubSectionData?.find(item => item.field_title_code=== "IMAGE4").data;
  const Image4 = SubSectionData?.find(item => item.field_title_code=== "IMAGE3").data;
  const Image5 = SubSectionData?.find(item => item.field_title_code=== "IMAGE6").data;
  const Image6 = SubSectionData?.find(item => item.field_title_code=== "IMAGE1").data;

  const bannerImageAltTag =bannerImageDataObjects?.img_alt_tag
 
    const topLeftImagealt =SubSectionData?.find(item => item.field_title_code=== "IMAGE1").img_alt_tag ?? null;
 

    const topRightImagealt =SubSectionData?.find(item => item.field_title_code=== "IMAGE5").img_alt_tag ?? null;
 

    const bottomLeftImagealt=SubSectionData?.find(item => item.field_title_code=== "IMAGE2").img_alt_tag ?? null;
   
    const bottomCenterImagealt =SubSectionData?.find(item => item.field_title_code=== "IMAGE4").img_alt_tag ?? null;
  
    const topCenterImagealt =SubSectionData?.find(item => item.field_title_code=== "IMAGE3").img_alt_tag ?? null;
   

    const bottomRightImagealt =SubSectionData?.find(item => item.field_title_code=== "IMAGE6").img_alt_tag ?? null;



  let keywords = sectionData?sectionData[0]?.meta_keyword:"";
  let slug = sectionData?sectionData[0]?.slug:"";
  let meta_title = sectionData?sectionData[0]?.meta_title:"";
  let meta_description = sectionData?sectionData[0]?.meta_description:"";



  // const [isBannerLoading, setIsBannerLoading] = useState(true);

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
 

  return (
    <>
     <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              onLoad={handleImageLoad} 
              onError={handleError}
             // style={{ display: isBannerLoading ? 'none' : 'block' }}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Villas
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Villas</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <AllVillas slugOfOurVillas={slugOfOurVillas}/>
      {/* <section>
        <div className="explore-kanifushi-villas villas-list-page section-padding ps-0 pe-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {AllVillas?.data?.data?.map((data) => {
                  // Calculate adult and child counts
                  // const adultCount = ` ${data.occupancy1_adult} adults + ${data.occupancy1_child} children or ${data.occupancy2_adult} adults + ${data.occupancy2_child} children`;
                  const occupancy = generateOccupancyText(
                    data.property_occupancy
                  );
                  const villaUserIcon = userOne;
                  const villaBedIcon = bedOne;
                  const villaAreaIcon = areOne;
                  const detailText = "View Details";
                  const viewDetailsLink = `/villa-details/${data.slug}`;
                  const bookNowLink = "2";
                  const bookNowText = "BOOK NOW";
                  const totalVillaAreaIcon = totalVillaIConView;
                  const villaArea = `Total Area - ${data.area_sqm}`;
                  const privatePoolIcon = privatePoolimg;
                  const showPrivatePool = false;
                  const showVillaArea = false;
                  const tagline = data?.tag_line;                
                
                 
              
                  return (
                    <VillasList
                      key={data.property_id}
                      villaName={data.property_name}
                      villaSlogan={tagline}
                      villaPara={truncateText(data.property_description, 87)}
                      adultCount={occupancy.join("\n")}
                      villaBed={data.property_beds.map((bed) => bed)}
                      villaUserIcon={villaUserIcon}
                      villaBedIcon={villaBedIcon}
                      villaArea={villaArea}
                      villaAreaIcon={villaAreaIcon}
                      viewDetailsLink={viewDetailsLink}
                      detailText={detailText}
                      bookNowLink={bookNowLink}
                      bookNowText={bookNowText}
                      slideVillaData={data.slideVillaData}
                      items={data.items}
                      villaImages={data.property_images}
                      villaThumbimages={data.property_images}
                      totalVillaAreaIcon={totalVillaAreaIcon}
                      totalVillaArea={villaArea}
                      privatePoolIcon={privatePoolIcon}
                      privatePoolArea={data.private_pool_size}
                      showVillaArea={showVillaArea}
                      showPrivatePool={showPrivatePool}
                      AllVillas={data}
                     
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <Glimpses
          image1={Image1}
          image2={Image2}
          image3={Image3}
          image4={Image4}
          image5={Image5}
          image6={Image6}

          bottomLeftImagealt={bottomLeftImagealt}
        topRightImagealt={topRightImagealt}
        bottomCenterImagealt={bottomCenterImagealt}
        topCenterImagealt={topCenterImagealt}
        bottomRightImagealt={bottomRightImagealt}
        topLeftImagealt={topLeftImagealt}

          slugOfGallery={slugOfGallery}

        />
      </section>
    </>
  );
}

export default OurVillas;
