import React,{useState} from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import innerCover from '../../assets/images/offers-cover.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThumbPressItem from '../ThumbPressItem';

import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPressReleaseData, getSinglePressReleaseData } from '../../services/dataServices';
import parse from 'html-react-parser';

import { useContext } from "react";
import { MyContext } from "../../Contex/LoadingContex";

import Helmet from "../../components/Helmet";
import PageNotFound from '../../components/PageNotFound';
import Loader from '../../components/Loader';
import BannerImage from '../../components/BannerImage';
import Image from '../../components/Image';
function PressReleaseDetails({setErrorPage,slugOfMedia}) {

    const { websitePressReleaseId } = useParams();
  const [isBannerLoading, setIsBannerLoading] = useState(true);
    
  let allPressRelease = useQuery("getAllPressRelease", getPressReleaseData);

  let singlePressRelease = useQuery(
    ["getSinglePressReleaseData", websitePressReleaseId],
    () => getSinglePressReleaseData(websitePressReleaseId)
  );
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const {isLoading,isSuccess}=allPressRelease

  if(singlePressRelease?.data?.data===404){

    return(
      <>
        <PageNotFound setErrorPage={setErrorPage}/>
      </>
    )
  }

   
  const keywords = singlePressRelease?.data?.data[0]?.keywords;
  const slug = singlePressRelease?.data?.data[0]?.slug;
  const meta_title = singlePressRelease?.data?.data[0]?.meta_title;
  const meta_description = singlePressRelease?.data?.data[0]?.meta_description;
   


  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  return (
    <>
    <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    {/* <img className='w-100 h-100' src={innerCover} title='' alt=''  onLoad={handleImageLoad} onError={handleError}/> */}
                    <BannerImage
              className="w-100 h-100"
              src={innerCover}
              title=""
              alt={''}
              isDataIsLoading={isLoading}
            />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Press Releases</h2>
                    </div>
                    <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${slugOfMedia}`}>Media</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {singlePressRelease?.data?.data[0].title}
              </Breadcrumb.Item>
            </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
        <div className="press-details section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4">
                <Link
                  className="w-auto btn primary theme-btn px-4 py-2 theme-outline-btn fw-medium text-uppercase border-theme form-control"
                  to={`/${slugOfMedia}`}
                >
                  <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back{" "}
                </Link>
              </div>

              <div className="col-md-8">
                {singlePressRelease?.data && (
                  <div className="press-article">
                    <div className="press-img rounded-3 overflow-hidden">
                      <Image
                        className="w-100"
                        src={singlePressRelease?.data?.data[0]?.image_path}
                        alt={singlePressRelease?.data?.data[0]?.image_alt_tag}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="press-title">
                        <h3 className="georgia text-uppercase">
                          {singlePressRelease?.data?.data[0].title}
                        </h3>
                        <span className="fw-medium mt-2 d-flex">
                          {singlePressRelease?.data?.data[0].releaseDate}
                        </span>
                      </div>
                      <div className="press-date pt-lg-4">
                        <span>
                          {/* {new Date(singlePressRelease?.data?.data[0].published_date).toLocaleDateString("en-GB")} */}
                        </span>
                      </div>
                    </div>
                    <div className="press-full-article">
                      <div className="press-text-card">
                        <div
                         
                        >{parse(singlePressRelease?.data?.data[0].description)}</div>
                        {/* <h5 className='fw-semibold mt-4'>{titleone}</h5>
                                                <div dangerouslySetInnerHTML={{ __html: presstexttwo }}></div> */}
                      </div>
                      {/* /press-text-card */}
                    </div>
                  </div>
                )}
                <div className="press-article-pagination">
                  <Link
                    className={`w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control ${
                      singlePressRelease?.data?.data[0].prev_id
                        ? ""
                        : "disabled"
                    }`}
                    to={
                      singlePressRelease?.data?.data[0].prev_id
                        ? `/press-release-details/${singlePressRelease?.data?.data[0].prev_id}`
                        : "#"
                    }
                  >
                    <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Prev
                  </Link>
                  <Link
                    className={`w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control ms-3 ${
                        singlePressRelease?.data?.data[0].next_id
                          ? ""
                          : "disabled"
                      }`}
                    to={`/press-release-details/${singlePressRelease?.data?.data[0].next_id}`}
                  >
                    Next{" "}
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
              </div>

              {/* /col-md-8 */}
              <div className="col-md-4">
                <div className="more-articles-sidebar mt-md-0 mt-5">
                  <div className="dashed-heading position-relative">
                    <h5 className="georgia text-uppercase">
                      <span>More articles</span>
                    </h5>
                  </div>
                  {/* allPressRelease && allPressRelease.data && allPressRelease.data.data.filter((item) => ( item !== 1 ? true : item.resort_name === screen.tabName)) */}
                  <div className="thumb-articles mt-md-4 mt-3 pt-md-2">
                    {allPressRelease &&
                      allPressRelease.data &&
                      allPressRelease.data.data.filter(data=>data.slug !== singlePressRelease?.data?.data[0].slug).map((thumbCard) => (
                        <ThumbPressItem
                          key={thumbCard.website_press_release_id}
                          id={thumbCard.website_press_release_id}
                          thumbCardImage={thumbCard.image_path}
                          imgAltTag={thumbCard.image_alt_tag}

                          thumbCardTitle={thumbCard.title}
                          thumbCardDate={thumbCard.published_date}
                          thumbPressLink={`/press-release-details/${thumbCard.slug}`}
                        />
                      ))}
                  </div>
                  <div className="press-article-pagination">
                    <Link
                      className="w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control"
                      to={`/${slugOfMedia}`}

                    >
                      {" "}
                      VIEW ALL{" "}
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /col-md-4 */}
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default PressReleaseDetails