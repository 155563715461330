import React from 'react'
import innerCover from '.././assets/images/inner-cove.jpg'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import comingSoon from '../assets/images/under-construction.svg'
import { Link } from 'react-router-dom';

function CoomingSoon() {
  return (
    <>
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100'>
                    <img className='w-100 h-100' src={innerCover} title='' alt='' />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Under Construction</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Under Construction</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>
        <section>
            <div className='coming-soon section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <div className='under-construction-img mb-5'>
                                <img src={comingSoon} alt='' title='' />
                            </div>
                            <h1 className='georgia text-uppercase'>Page is Under Construction</h1>
                            <div className='back-to-home mt-4'>
                                <Link to='/' className='btn theme-btn fw-medium text-white text-uppercase border-theme'>Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CoomingSoon