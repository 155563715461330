import React,{useState} from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import MediaTabs from './MediaTabs.js'
import { Link } from 'react-router-dom';
import { getPageData,getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import Helmet from "../../components/Helmet";
import { useContext } from "react";
import { MyContext } from "../../Contex/LoadingContex";
import Loader from '../../components/Loader.js';
import BannerImage from '../../components/BannerImage.js';

function Media({isPreview,userId,sectionId}) {
    const pageCode = "RMEDIA";

    const pagesSectionData1 = useQuery(
        ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
        () => getPageData1(pageCode, isPreview, userId, sectionId)
      );
      
const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

const {isLoading,isSuccess}=pagesSectionData1

const [isBannerLoading, setIsBannerLoading] = useState(true);





    const bannerImage =
        pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MEDIA" && item.field_label_code === "BANNERIMAGE"
          )[0]?.psdd_data ?? null;
      
    const pagesSectionData = useQuery(["pagesSectionData", pageCode,isPreview,userId,sectionId], () =>
      getPageData(pageCode,isPreview,userId,sectionId)
    );
    
    const manageMedia=pagesSectionData?.data?.data?.map((sectionData) => (
    sectionData?.section_details?.filter((item) => item.section_code === "MEDIA")[0]??null));
    // const bannerImage =manageMedia?.[0]?.section_elements
    //    ?.filter((item) => item.field_label_code === 'BANNERIMAGE')[0]?.data ?? null;
 
       const bannerImageAltTag =
       manageMedia?.[0]?.section_elements?.filter(
         (item) => item.field_label_code === "BANNERIMAGE"
       )[0]?.img_alt_tag ?? null;
       const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
       const slug = pagesSectionData?.data?.data[0]?.slug;
       const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
       const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

      //  const handleImageLoad = () => {
      //   document.body.classList.remove('slider-open');
      //   setIsBannerLoading(false);
      //   setIsLoadingMain(false);
      // };
      // const handleError = () => {
      //   document.body.classList.remove('slider-open');
      //   setIsBannerLoading(false);
      //   setIsLoadingMain(false);
      // };
      // if (isLoading) {
      //   setIsLoadingMain(true);
      //   document.body.classList.add('slider-open');
      //   return <Loader />;
      // }
      // else if (isBannerLoading) {
      //   document.body.classList.add('slider-open');
      //   setIsLoadingMain(true);
      // }
  return (
    <>
     <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
        <section>
            <div className='inner-cover header-space position-relative'>
                <div className='our-story position-absolute start-0 top-0 h-100 cover-overlay'>
                    {/* <img className='w-100 h-100' src={bannerImage} title='' alt={bannerImageAltTag}  onLoad={handleImageLoad} onError={handleError} /> */}
                    <BannerImage
              className="w-100 h-100"
              src={bannerImage && bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />
                </div>
                <div className='page-inner position-relative text-center'>
                    <div className='page-title d-flex justify-content-center'>
                        <h2 className='text-uppercase text-white georgia py-xl-4 py-3 px-5'>Media</h2>
                    </div>
                    <Breadcrumb className='d-flex justify-content-center mt-4 text-white'>
                        <Breadcrumb.Item><Link to='/'>Home</Link> </Breadcrumb.Item>
                        <Breadcrumb.Item active>Media</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </section>

        <section>
            <div className='sub-brands-tabs media-center-tabs section-padding'>
                <MediaTabs manageMedia={manageMedia}/>
            </div>
        </section>

    </>
  )
}

export default Media