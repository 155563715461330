import React from 'react'
import AkiraAccordion from './AkiraAccordion'
import { Accordion } from 'react-bootstrap'
import Image from '../../components/Image'

function AkiraFeatures({ akiraFeatureImg = "",imageAltTag="", akiraSmallText = "", akiraHeading = "", akiraPara = "", akiraAccordionData = [], showSmallHeading = true }) {
    return (
        <>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='akira-text-card'>
                   {showSmallHeading?(<small className="text-uppercase">{akiraSmallText}</small>):("")}     
    
                        <h2 className='georgia text-uppercase mt-3 mb-4'>{akiraHeading}</h2>
                        <div className='akira-des'>
                            <p>{akiraPara}</p>
                        </div>
                        <div className='akira-faqs shadow-none bg-transparent rounded-0 border-0 p-0'>
                            {/* <Accordion defaultActiveKey="0">
                        {akiraAccordionData.map(accData=>(
                            <AkiraAccordion akraEventKey={accData.akraEventKey} akiraAccordionTitle={accData.akiraAccordionTitle} akiraAccordionBody={accData.akiraAccordionBody} />
                        ))}
                        </Accordion> */}

                            <Accordion defaultActiveKey="0">

                                {
                                    akiraAccordionData.map((accData,index) => {
                                        const accTitle=accData.sub_section_title
                                        const accBody =accData.elements.filter((item)=>
                                            item.field_title_code==="DESCRIPTION"
                                        )[0].data
                                        const key = accData.page_sub_section_id
                                        return (
                                            <AkiraAccordion key={index} akraEventKey={key} akiraAccordionTitle={accTitle} akiraAccordionBody={accBody} />
                                        )
                                    })
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='akira-feature-image overflow-hidden'>
                        <Image className='w-100' src={akiraFeatureImg} alt={imageAltTag} title='' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AkiraFeatures