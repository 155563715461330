import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import parse from "html-react-parser";
import Image from "../../components/Image";
function OtherRestaurants({ restaurantItems, otherRestaurantsSlides, oldSlug ,slugOfDining}) {
  const settings = { ...otherRestaurantsSlides };
  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);


    // If there is no space in the truncated part, just return the original truncated tex
    return truncatedText;
  }

  return (
    <>
      <Slider className="slick-arrow-default" {...settings}>
        {restaurantItems?.map((item) => {
          if (item.slug === oldSlug) {
            return
          }
          const imageAltTag = item.image_alt_tag;
          const restTag = item.restaurant_tags.map((data, index) => {
            if (index === 0) {
              return data;
            } else {
              return `  - ${data}`;
            }
          });
          return (
            <div
              className={`restaurant-slide-card position-relative `}
            >
              <div className="restaurant-img overflow-hidden position-relative">
                <Image
                  className="w-100"
                  src={item.restaurant_image}
                  alt={imageAltTag}
                // title={item.restaurant_name}
                />
                <span className="dining-cat position-absolute start-0 top-0 m-3 text-white text-uppercase" style={{ backgroundColor: `${item.color_code}` }}>
                  <small>{item.restaurant_type}</small>
                </span>
              </div>
              <div className="restaurant-text">
                <h5 className="text-uppercase georgia mt-lg-5 mt-4 mb-lg-3 mb-2 letter-spacing-2">
                  {item.restaurant_name}
                </h5>
                {/* <span>{restTag}</span> */}
                <p className="mt-lg-3 mt-2 mb-lg-4 mb-3">
                  {/* {item.restaurant_description} */}
                  {item.restaurant_tagline.length > 50 ? (
                    <>
                      {parse(truncateAtLastWord(item.restaurant_tagline, 80))}

                      {" ...."}
                    </>
                  ) : (
                    parse(item.restaurant_tagline)
                  )}
                </p>
                <div className="restaurant-action">
                  <Link
                    to={`/${slugOfDining}/${item.slug}`}
                    className="btn p-0 d-flex align-items-center"
                  >
                    {"LEARN MORE"} <IoIosArrowForward className="ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default OtherRestaurants;
