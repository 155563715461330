import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../../components/Image'

function CollateralCards({docTypeIcon, collateralTitle, collateralPara, docDownloadIcon, downloadCollateral}) {
  return (
    <>
        <div className='collateral-card border d-flex align-items-center justify-content-between'>
            <div className='collateral-docs-type d-flex align-items-center justify-content-center'>
                <Image src={docTypeIcon} alt='' title='' />
            </div>
            <div className='collateral-text'>
                <h5>{collateralTitle}</h5>
                <p>{collateralPara}</p>
            </div>
            <div className='download-docs'>
                <Link to={downloadCollateral} target='_blank' className='d-flex align-items-center justify-content-center'>
                    <Image src={docDownloadIcon} alt="Download" title="Download" />
                </Link>
            </div>
        </div>
    </>
  )
}

export default CollateralCards