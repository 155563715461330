import React from 'react'
import Slider from 'react-slick'
import Image from '../../components/Image'

function GallerySlides({gallerySliderSettings,galleryslidedata,onUpdate,callfrom=""}) {
    const settings = {...gallerySliderSettings}
  
  return (
    <>
        <Slider className='slick-arrow-default' {...settings}>
        {galleryslidedata
    ?.filter((item) => {
      const images = item?.elements?.find(
        (element) =>
          element.field_title_code === "IMAGE" && element.data !== null && element.data!==""
      );
      return images !== undefined;
    }).map((item,index)=>{
          // const images=  item?.elements?.filter(
          //       (item) => item.field_title_code === "IMAGE"
          //     )[0]?.data ?? null;
          const images = item?.elements?.filter(
            (item) => item.field_title_code === "IMAGE" && item.data !== null
          )[0]?.data ?? null;
          const imagesImgAltTag = item?.elements?.filter(
            (item) => item.field_title_code === "IMAGE" && item.data !== null
          )[0]?.img_alt_tag ?? null;
            return(
              <div className='gallery-slide h-100' key={index} 
                onMouseEnter={callfrom=== "slider" ?()=>onUpdate(index):()=>{}
                }> 
                    <img src={images} alt={imagesImgAltTag} title='' /> 
                </div>
            )})}
        </Slider>
    </>
  )
}

export default GallerySlides