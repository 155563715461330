import React from "react";
import { Helmet as Hel } from "react-helmet";
import { getCanonicalUrl } from "../services/dataServices";
import { useQuery } from "react-query";
function Helmet({ title, metaDescription, slug, keywords }) {
  const websiteUrl = useQuery(["canonical_url", "canonical_url_raaya"], () =>
    getCanonicalUrl("canonical_url_raaya")
  );
  const baseUrl = websiteUrl?.data?.data?.setting_value;
  const baseCanonicalUrl = baseUrl;
  const canonical = slug ? `${baseUrl}/${slug}` : baseCanonicalUrl;



  const afTags = () => {
    var sc = document.createElement("script");
    sc.type = "text/javascript";
    sc.async = true;
    sc.src = "//customs.affilired.com/track/?merchant=4796";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(sc, s);
  };

  return (
    <Hel>
      {title ? <title>{title}</title> : <title>Raaya By Atmosphere</title>}
      <link rel="canonical" href={canonical} />

      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      {/* script start  */}
   
      <script type="text/javascript">
        {`(function() {
  var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true;
  sc.src = '//customs.affilired.com/track/?merchant=4796';
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sc, s);
  })();`}
      </script>
  {/* script end   */}
    </Hel>
  );
}

// slug: "test-offer-121"
// keywords: "hello keywords"
// meta_title: "hello meta title"
// meta_description: "hello meta description"

export default Helmet;
