import React from 'react'
//import image from "../assets/images/raaya-loader-logo.svg"

const Loader = ({image}) => {
  return (
    <section>
    <div className="logo-fade-animation">
        <div className="logo-overlay-wrapper">
            <div className="logo-wrapper">
                {/* <img src={image} alt=""/> */}
                <span className="overlay white-overlay"></span>
            </div>
        </div>
    </div>
</section>
  )
}

export default Loader