import { Button } from 'react-bootstrap';
import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5'; // Import close icon from react-icons
import { Scrollbars } from 'react-custom-scrollbars';
import Image from '../../components/Image';

function PlanCard({
  planCardTitle = "",
  planSlogan = "",
  sortDes = "",
  planImage = "",
  planConditionText = "",
  pointlist = [],
  isexceedinglimit = false,
  showSlogan = true,
  showMoreTextPopup = true,
  showSortDes = false,
  showCondtionText = false,
  allDescText,
  imgAltTag = "",

}) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTcOpen, setIsModalTcOpen] = useState(false);

  function openModal(val) {
    if (val === "ReadMore") {
      setIsOpen(true);
    } else {
      setIsModalTcOpen(true);
    }
  }

  function closeModal(val) {
    if (val === "ReadMore") {
      setIsOpen(false);
    } else {
      setIsModalTcOpen(false);
    }
  }

  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);
    return truncatedText;
  }
  const allconditionText = planConditionText;

  const truncateText = (text = "", length) => {
    return text?.length > +length ? text.slice(0, +length) + "..." : text;
  };

  const scrollbarsRef = useRef(null);
  const handleScroll = ({ }) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollbarsRef.current.getValues();
    const scrollContainer = scrollbarsRef.current.container;


    if (scrollTop === 0) {
      // Top of the scroll
      scrollContainer.classList.remove('scroll-bottom-gradient-end');
      scrollContainer.classList.add('scroll-bottom-gradient');
    } else if (scrollTop + clientHeight === scrollHeight) {
      // Bottom of the scroll
      scrollContainer.classList.remove('scroll-bottom-gradient');
      scrollContainer.classList.add('scroll-bottom-gradient-end');
      // You can trigger your alert here
    }
  };

  return (
    <>
      <div className='col-lg-6 mb-4'>
        <div className='card-plan h-100'>
          {pointlist.length !== 0 ? (
            <div className='plan-card-text'>
              <h5 className='text-uppercase georgia letter-spacing-2'>{planCardTitle}</h5>
              {showSlogan ? (<span className='text-uppercase georgia slogan-text d-flex w-100'>{planSlogan}</span>) : ("")}
              {showSortDes ? (<p>{sortDes}</p>) : ("")}
              <Scrollbars ref={scrollbarsRef} className='play-scrollbar scroll-bottom-gradient' style={{ height: 301 }} onScroll={handleScroll} >
                <div className='custom-scrollbar'>
                  <div className='plan-more-view'>
                    <div className='feature-point-list position-relative pb-4'>
                      {pointlist && (<><ul className='list-unstyled m-0 p-0'>
                        {pointlist?.map((item, index) => <li key={index}><span className='d-flex pe-3 w-100'>{item}</span></li>)}
                      </ul></>)}

                    </div>
                    <div className='term-condition-text d-flex mt-3'>
                      <em>{planConditionText}</em>
                    </div>
                  </div>
                </div>
              </Scrollbars>


              {/* {showCondtionText && (
    <div className='term-condition-text d-flex mt-3'>
        <em>{planConditionText.length > 46? truncateAtLastWord(planConditionText, 46) + "..." : planConditionText}</em>
        {planConditionText.length > 46 && <em><u><span className='cursor-pointer' onClick={() => openModal("ViewMore")}>View More</span></u></em>}
    </div>
)} */}



              <div className='more-text-modal'>
                {/* <Modal
        className='read-more-modal'
        isOpen={modalTcOpen}  // Use modalTcOpen state
        onRequestClose={() => closeModal("ViewMore")}  // Use closeModal function with "ViewMore" parameter
        contentLabel="Example Modal">
        <button className="btn close-icon" onClick={() => closeModal("ViewMore")}>
            <IoClose />
        </button>
    
            {allconditionText}
              
     
    </Modal> */}
              </div>

            </div>
          ) : (
            ""
          )}
          {planImage ? (
            <div className='plan-feature-img h-100'>
              <Image src={planImage} alt={imgAltTag} title='' />
            </div>
          ) : (
            null
          )}
        </div>
      </div>
    </>
  );
}

export default PlanCard;
