import React from 'react'
import Image from './Image'

function SustainBottomFeatureBox({bottomimages,greenBottomSustain, greenButtomSustainImage,greenButtomSustainImageAltTag}) {
   
  return (
    <>
        <div className='col-lg-6  mb-lg-0 mb-4'>
          <div className='green-sustain'>
          {/* <img src={ greenButtomSustainImage} alt={greenButtomSustainImageAltTag} title=''/> */}
          <Image src={ greenButtomSustainImage} alt={greenButtomSustainImageAltTag} title=''/>
          </div>
        </div>
        {bottomimages.map((image,index)=>(
              <div key={index} className='col-lg-3 col-sm-6'>
                <div className='sustain-card pt-xl-5 pt-4 pb-3 px-2'>
                    <div className={'card-image d-flex align-items-center justify-content-center m-auto'}>
                    <Image src={image.sustainFeatureImg} alt='' title='' />
                    </div>
                    <div className='sustain-text-box px-xl-2 mt-xl-4 mt-3 rounded-4 ms-auto me-auto text-center'>
                    <h5 className='georgia mb-2 pb-1'>{image.sustainHeading}</h5>
                    <p>{image.sustainPara}</p>
                    </div>
                </div>
              </div>
        ))}
    </>
  )
}

export default SustainBottomFeatureBox