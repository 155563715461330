import React from 'react'
import parse from 'html-react-parser'

import AwardCard from './AwardCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getAllAwards } from '../../services/dataServices'
import { useQuery } from 'react-query';
import Image from '../../components/Image'
function MediaAwards({smallHeading = 'AWARDS',heading= 'AWARDS',manageMedia}) {
  const allAwards = useQuery("getAllAwards", getAllAwards);
  const awardsData=allAwards?.data?.data;
  
  // const awardData = [
  //   {
  //     id: 0,
  //     awardImage: awardTwentySix,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'World Travel Awards'
  //   },
  //   {
  //     id: 1,
  //     awardImage:awardNineteen,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Tripadvisor'
  //   },
  //   {
  //     id: 2,
  //     awardImage:  awardTwenty,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'World Travel Awards'
  //   },
  //   {
  //     id: 3,
  //     awardImage: awardTwentyOne,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:  'World Travel Awards'
  //   },
  //   {
  //     id: 4,
  //     awardImage: awardSeventeen,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Tripadvisor'
  //   },
  //   {
  //     id: 5,
  //     awardImage: awardTwentyThree,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:'World Travel Awards'
  //   },
  //   {
  //     id: 6,
  //     awardImage: awardTwentyFour,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Hotel Asia'
  //   },
  //   {
  //     id: 7,
  //     awardImage: awardTwentyFive,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Hotel Asia'
  //   },
  //   {
  //     id: 8,
  //     awardImage: awardOne,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Hotel Asia'
  //   },
  //   {
  //     id: 9,
  //     awardImage:awardTwo,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:  'Hotel Asia'
  //   },
  //   {
  //     id: 10,
  //     awardImage: awardThree,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:'TEZ WORLDBERRY Russia'

  //   },
  //   {
  //     id: 11,
  //     awardImage: awardFour,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Tripadvisor Global'
      
  //   },
  //   {
  //     id: 12,
  //     awardImage: awardFive,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Vacation Media US'
  //   },
  
  //   {
  //     id: 13,
  //     awardImage: awardSix,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:'TEZ WORLDBERRY Russia'
  //   },
  //   {
  //     id: 14,
  //     awardImage: awardSeven,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Luxury Travel Global UK'
  //   },
  //   {
  //     id: 15,
  //     awardImage: awardEight,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:  'Selling Travel – UK'
  //   },
  //   {
  //     id: 16,
  //     awardImage: awardTwentySeven,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Tripadvisor Global'
  //   },
  //   {
  //     id: 17,
  //     awardImage: awardNine,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Thomas Cook International'
  //   },
  //   {
  //     id: 18,
  //     awardImage: awardEleven,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName:'Destinology UK'
  //   },
  //   {
  //     id: 19,
  //     awardImage: awardFive,
  //     awardLogo: awardLogoOne,
  //     awardCompanyName: 'Vacation Media US'
  //   },
  //   // {
  //   //   id: 20,
  //   //   awardImage: awardTwentyOne,
  //   //   awardLogo: awardLogoOne,
  //   //   awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
  //   // },
  //   // {
  //   //   id: 21,
  //   //   awardImage: awardTwentyTwo,
  //   //   awardLogo: awardLogoOne,
  //   //   awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
  //   // },
  //   // {
  //   //   id: 23,
  //   //   awardImage: awardTwelve,
  //   //   awardLogo: awardLogoOne,
  //   //   awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
  //   // },

  //   // {
  //   //   id: 24,
  //   //   awardImage: awardEleven,
  //   //   awardLogo: awardLogoOne,
  //   //   awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
  //   // },
  //   // {
  //   //   id: 25,
  //   //   awardImage: awardFive,
  //   //   awardLogo: awardLogoOne,
  //   //   awardCompanyName: 'Hotel Asia Exhibition & International Culinary Challenge'
  //   // }
  // ]
/*******************************MEDIA AWARDS START********************************* */
let pressReleasesAwardsData = manageMedia?.[0]?.subSectionData?.filter(
  (item) => item.sub_section_title_code === "AWARDS"
);
const awardsIcon =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "ICON"
  )[0]?.data ?? null;


const  awardsHeading =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "HEADING"
  )[0]?.data ?? null;

const  awardsDescription =
  pressReleasesAwardsData?.[0]?.elements?.filter(
    (item) => item.field_title_code === "DESCRIPTION"
  )[0]?.data ?? "";

/*******************************MEDIA AWARDS END********************************* */
  return (
    <>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='heading-icon mb-xl-4 mb-2 pt-2 pb-2'>
                  <Image src={awardsIcon} title='' alt='' />
              </div>                      
              <h1 className='text-uppercase georgia heading-space mt-xl-4 mt-2'>{awardsHeading}</h1>
              <div className='media-para m-auto pt-3 text-center'>
                <p>
                {parse(awardsDescription)}
                </p>
              </div>
            </div>
          </div>
          <div className='awards-list mt-lg-5 mt-4'>
            <div className='row gy-4'>
              {awardsData?.map((award) => (
                <div className='col-lg-4 col-md-6' key={award.award_id}>
                  <AwardCard 
                      awardName={award.name}
                      awardee={award.awardee}   
                      year={award.year}           
                    // awardImage={award.awardImage}
                    // awardLogo={award.awardLogo}
                    // awardCompanyName={award.awardCompanyName}
                  />
                  </div>
                ))}                
              </div>
              <div className='show-more d-flex justify-content-center d-none'>
                <Link to='/' className='w-auto btn primary theme-btn theme-outline-btn fw-medium text-uppercase border-theme form-control'>Show More <FontAwesomeIcon icon={faAngleRight} className='ms-2' /></Link>
              </div>
            </div>
        </div>
    </>
  )
}

export default MediaAwards