import React from 'react'
import rayaWhiteLogo from '../assets/images/Raaya-logo-white.svg'
import rayaTxtLogo from '../assets/images/raya-text-logo.svg'
import { Link, NavLink } from 'react-router-dom';
import menuImg from '../assets/images/menu.svg'
import rayaMobileLogo from '../assets/images/raya-text-logo.svg'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Modals from './Modals';
import { getWebMenu } from '../services/dataServices'
import { useQuery } from 'react-query';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = ({ pageNoteFoundBackground }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [menuItem, setMenuItem] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [mailSuccessMsg, setMailSuccessMsg] = useState("");
    // function to get menu 
    const navigationItems = useQuery(
        "navigationItems",
        getWebMenu
    )
    const handleSetMailSuccessMsg = (message) => {
        setMailSuccessMsg(message);
        setTimeout(() => {
            setMailSuccessMsg('');
            setShow(false)
        }, 4000);
    };

    const middleIndex = Math.floor(navigationItems.data?.data?.length / 2);
    const renderSubMenu = (items, parentTitle) => {
        return (
            <NavDropdown title={parentTitle} className='text-white nav-link' id="basic-nav-dropdown">
                {items.map((subItem, index) => (
                    <React.Fragment key={index}>
                        {subItem.child ? (
                            <NavDropdown.Item title={subItem.title} id={`submenu-${subItem.menuId}`}>

                                {renderSubMenu(subItem.child, subItem.title)}
                            </NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item>
                                <NavLink className='text-white nav-link' to={subItem.slug}>
                                    <span>{subItem.title}</span>
                                </NavLink>
                            </NavDropdown.Item>
                        )}
                    </React.Fragment>
                    // <NavDropdown.Item key={subItem.menuId}>
                    //     <NavLink className='text-white nav-link' to={subItem.slug}>
                    //         <span>{subItem.title}</span>
                    //     </NavLink>
                    // </NavDropdown.Item>
                ))}
            </NavDropdown>
        );
    };
    return (

        <>
            <header className={`start-0 top-0 end-0 ${pageNoteFoundBackground}`}>
                <div className='header-wrap position-relative'>
                    <div className='container-fluid'>
                        <div className='row position-relative'>
                            <button className='menu-btn border-0 bg-transparent position-absolute z-2 d-none'>
                                <img src={menuImg} alt='' title='' />
                            </button>
                            {/* /col */}
                            <div className='col d-xl-none'>
                                <div className='logo-device-col'>
                                    <div className='device-logo position-relative'>
                                        <Link to='/'><img src={rayaMobileLogo} title='' alt='' /></Link>
                                    </div>
                                    <div className='mobile-book-now d-xl-none'>
                                        {/* <Button className='btn outline-success text-white book-btn text-uppercase px-4 py-2 book_now_header_ga4' onClick={handleShow}>Book Now</Button> */}
                                        <Link className='btn outline-success text-white book-btn text-uppercase px-4 py-2 book_now_header_ga4' to={'https://reservations.raaya-atmosphere.com/book/dates-of-stay'} target='_blank'>Book Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>


                                <div className='atmosphere-living-nav'>
                                    <Navbar expanded={expanded} expand="xl" className="navbar-nav smooth">
                                        <Navbar.Toggle className="shadow-none" aria-controls="basic-navbar-nav" onClick={() => setExpanded(prev => !prev)} />
                                        <Navbar.Collapse className='w-100' id="basic-navbar-nav">
                                            <Nav as='ul' className="nav-ul start-nav-list me-auto d-flex align-items-center text-uppercase">
                                                {/* <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to='/'><span>Home</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)}   as='li'><NavLink className='text-white nav-link' to="/our-brands-page"><span>OUR SUB BRANDS</span></NavLink></Nav.Item>
                                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/our-story"><span>OUR STORY</span></NavLink></Nav.Item>
                                     */}
                                                {navigationItems.data?.data?.slice(0, middleIndex + 1).map((item, index) => (
                                                    <Nav.Item key={index} onClick={() => setExpanded(!expanded)} as='li'>
                                                        {item.child ? (
                                                            renderSubMenu(item.child, item.title)
                                                        ) : (
                                                            <NavLink className='text-white nav-link' to={item.slug}>
                                                                <span>{item.title}</span>
                                                            </NavLink>
                                                        )}

                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                            {/* NAV CLOSED  */}
                                            <div className='atmosphere-centered-logo ms-auto me-auto'>
                                                <Link onClick={() => setExpanded(!expanded)} className='text-white position-relative d-flex' to="/">
                                                    <img className='position-absolute end-0 start-0 m-auto logo-fixed smooth' src={rayaTxtLogo} title='' alt='' />
                                                    <img className='position-absolute end-0 start-0 m-auto logo-sticky smooth' src={rayaWhiteLogo} title='' alt='' />
                                                </Link>
                                            </div>

                                            {/* <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/sustainability"><span>SUSTAINABILITY</span></NavLink></Nav.Item>
                                                <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to='/offers'><span>OFFERS</span></NavLink></Nav.Item> */}

                                            <Nav as='ul' className="nav-ul end-nav-list me-auto d-flex align-items-center text-uppercase">

                                                {navigationItems.data?.data?.slice(middleIndex + 1).map((item, index) => (
                                                    <Nav.Item key={index} onClick={() => setExpanded(!expanded)} as='li'>
                                                        {item.child ? (
                                                            renderSubMenu(item.child, item.title)
                                                        ) : (
                                                            <NavLink className='text-white nav-link' to={item.slug}>
                                                                <span>{item.title}</span>
                                                            </NavLink>
                                                        )}
                                                    </Nav.Item>
                                                ))}
                                                {/* {mailSuccessMsg ? (
                                                    <span className='text-white'>{mailSuccessMsg}</span>
                                                ) : ( */}

                                                <Nav.Item onClick={() => setExpanded(!expanded)} className='booking-nav-item d-none d-lg-flex' as='li'>
                                                    {/* <Button className='btn outline-success book-btn text-uppercase px-4 py-2 book_now_header_ga4 ' onClick={handleShow}>Book Now</Button> */}
                                                    <Link className='btn outline-success book-btn text-uppercase px-4 py-2 book_now_header_ga4 ' to={'https://reservations.raaya-atmosphere.com/book/dates-of-stay'} target='_blank'>Book Now</Link>

                                                </Nav.Item>
                                                {/* )} */}

                                                <Modals show={show} setShow={setShow} handleClose={handleClose} mailSuccessMsg={mailSuccessMsg} setMailSuccessMsg={handleSetMailSuccessMsg} />
                                            </Nav>

                                        </Navbar.Collapse>
                                    </Navbar>

                                </div>
                                {/* /atmosphere-living-nav */}
                            </div>
                            {/* /col */}
                        </div>
                        {/* /row */}
                    </div>
                    {/* container-fluid */}
                </div>
            </header>
        </>
    )
}

export default Header