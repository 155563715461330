import React from "react";
import PlanTabItem from "./PlanTabItem";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";

import Image from "./Image";

const ExplorePlanTabs = ({ PlanData }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    
    setActiveTab(index);
  };
 

  
  const allPlanData = PlanData && PlanData[0]?.subSectionData;
  return (
    <>
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
        allPlanData={allPlanData}
      >
        {/* generating an array to loop through it  */}

        {PlanData &&
          PlanData[0]?.subSectionData.map((subData, index) => {
            const title =
              subData?.elements?.filter(
                (item) => item.field_title_code === "TITLE"
              )[0]?.data ?? null;
              const icon =
              subData?.elements?.filter(
                (item) => item.field_title_code === "ICON"
              )[0]?.data ?? null;

            return (
              <Tab
                key={index}
                className={`item ${
                  activeTab === index
                    ? "active text-uppercase d-flex align-items-center"
                    : "text-uppercase d-flex align-items-center"
                }`}
              >
                <span className="tab-feature-img d-flex rounded-circle overflow-hidden">
                  <Image src={icon} alt="" title="" />
                </span>
                <span className="tab-name">{title}</span>
              </Tab>
            );
          })}
      </Tabs>
      {PlanData &&
        PlanData[0]?.subSectionData.map((SubChlidAccordionData, index) => {
          const planFaqHeading =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "TITLE"
            )[0]?.data ?? null;
          const tabFeatureImage =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "MAINIMAGE"
            )[0]?.data ?? null;
            const tabFeatureImageAltImage =
            SubChlidAccordionData?.elements?.filter(
              (item) => item.field_title_code === "MAINIMAGE"
            )[0]?.img_alt_tag ?? null;
          return (
            <TabScreen
              key={index}
              activeTab={activeTab}
              index={index}

              // You can add animation with adding a custom class
            >
              <PlanTabItem
                tabFeatureImage={tabFeatureImage}
                tabFeatureImageAltImage={tabFeatureImageAltImage}
                planFaqHeading={planFaqHeading}
                accordionData={SubChlidAccordionData}
              />
            </TabScreen>
          );
        })}
    </>
  );
};

export default ExplorePlanTabs;
