
import React, { useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import 'react-lazy-load-image-component/src/effects/blur.css';
const Image = ({
  src = "",
  style = "",
  alt = "",
  title = "",
  className = "",
}) => {
  const [loaded, setLoaded] = useState(false);
  const urlParts = src?.split("/");
  const baseURL = urlParts?.slice(0, -2).join("/") + "/";
  const filename = urlParts?.[urlParts?.length - 1];
  const blurredFilename = "thumbnails/blurred-" + filename;
  const blurredURL = baseURL + blurredFilename;
  
  const handleLoad = () => {
    setLoaded(true);
  };
  return (
    // <LazyLoadImage
    //         alt={alt}
    //         src={src}
    //         placeholder={<img src={blurredURL}/>}
    //         className={`${className} w-full`}
    //         onLoad={handleLoad}
    //     />
    
    <img
      className={`${className}`}
      src={loaded ? src : blurredURL}
      alt={alt}
      onLoad={handleLoad}
      onError={() => setLoaded(true)}
      title={title}
    />
  );
};
export default Image;