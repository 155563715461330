import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Image from "../components/Image";

function OtherVillas({slugOfOurVillas, othersVillaSettings, otherVillaView,singleVillaData }) {
  const settings = { ...othersVillaSettings };
  return (
    <Slider {...settings}>
      {otherVillaView &&
        otherVillaView?.data &&
        otherVillaView?.data?.data
          ?.filter(
            (data) =>
              data.slug !=
              singleVillaData?.data?.data?.property_data[0].slug
          )
          .map((othervilla,index) => 
          {
            const villaImageUrl = othervilla.property_images[0];
            const imgAltTag = othervilla.property_images[0].property_image_alt_tag;

            const villaLinkText = 'VIEW DETAILS'
          
          return(
            <div className="other-villa-card pb-1" key={index}>
              <div className="villa-image">
                <Image src={villaImageUrl.image_path} alt={imgAltTag} title="" />
              </div>
              <div className="other-villa-text">
                <h5 className="text-uppercase georgia d-flex align-items-center mt-xl-4 mt-2 pt-xl-2 mb-xl-4 mb-2 pb-xl-2">
                  {othervilla.property_name}
                </h5>
                <Link
                  to={`/${slugOfOurVillas}/${othervilla.slug}`}
                  className="georgia btn theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                >
                  {villaLinkText}
                </Link>
              </div>
            </div>
          )})}
    </Slider>
  );
}

export default OtherVillas;
