import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import innerCover from "../../assets/images/privacy-back-cover.jpg";
import { Link } from "react-router-dom";
import ConditionList from "../../components/ConditionList";
import { useQuery } from "react-query";
import { getPageData, getPageData1 } from "../../services/dataServices";
import parse from "html-react-parser";

import Helmet from "../../components/Helmet";

import BannerImage from "../../components/BannerImage";

function PrivacyPolicy({ isPreview, userId, sectionId }) {
  const pageCode = "RPRIVACYPOLICY";

  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  // const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const { isLoading, isSuccess } = pagesSectionData1;

  // const [isBannerLoading, setIsBannerLoading] = useState(true);

  const privacyImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "MANAGEPP" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ?? "";

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  const privacyData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MANAGEPP"
      )[0] ?? ""
  );

  const privacyImageAltTag =
    privacyData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? "";

  const privacyContent =
    privacyData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "PRIVACYPOLICY"
    )[0]?.data ?? "";

  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //  setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //  setIsBannerLoading(false);
  //  setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img className='w-100 h-100' src={privacyImage} title='' alt={privacyImageAltTag} onLoad={handleImageLoad} onError={handleError}/> */}
            <BannerImage
              className="w-100 h-100"
              src={privacyImage}
              title=""
              alt={privacyImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Privacy Policy
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <sectio>
        <div className="privacy-policy-wrapper section-padding">
          <div className="container">
            <div className="conditions-inner-wrapper">
              {parse(`${privacyContent}`)}
              {/* {parse(`${privacyContent}`)} */}
              {/* <p><span className='text-uppercase'>Updated:</span>3rd March, 2024</p>
                            <p className='condition-space mb-0'>Atmosphere Core Pvt Ltd (“AHR”) respects the privacy of all its customers, business clientele, and treats personal information provided by you as confidential. We pledge to fully comply with the requirements of the International Personal Data (Privacy) Ordinance. We use your personal data and information in order to fulfill our commitment to provide you with distinctive Atmosphere standards of hospitality service. This Privacy Policy explains our practices regarding the personal information we collect from you or about you on this site or via our apps, through written or verbal communications with us, when you visit one of our properties, or from other sources.</p>
                            <p>Resident of the European Economic Area (EEA), please <Link to="https://www.atmospherecore.com/gdpr-complience" target='_blank'>Click Here</Link> to view additional information regarding AHR’s use of your personal information.</p>
                            
                            <p>In response to The Digital Markets Act (DMA) and Third-party cookies deprecation, Atmosphere Core Pvt Ltd (“AHR”) has also implemented consent mode plug-ins following Google own <Link to='https://policies.google.com/privacy' target="_blank">Privacy Policy</Link> and <Link to='https://policies.google.com/technologies/partner-sites' target="_blank">Ad personalization mechanism</Link></p>

                            <h6 className='condition-space mb-0 text-uppercase'>capturing personal information</h6>
                            <p>We collect personal information at every touch point or guest interaction, and in conducting every aspect of our business. This personal information may include: your name, mailing address, billing address, email address, phone number, information related to your reservation, stay or visit to a property; information related to the purchase and receipt of products and/or services; nationality, passport number and date and place of issue; travel history; payment information, such as your payment card number and other card information, as well as authentication information and other billing and account details associated with mobile billing; guest preferences; marketing and communication preferences; reviews and opinions about our Portfolio of Brands or properties (including but not limited to: Atmosphere, OZEN, OBLU, OBLU Select and OBLU X); and other types of information that you choose to provide to us or that we may obtain about you.</p>
                            <p>Furthermore, we collect other personal information in certain cases, such as;</p>
                            <p>Surveys: We may request demographic data or other personal information in customer surveys.</p>
                            <p>On-property Collection: We collect additional personal information during registration/check-in at our properties, including such information as may be required by local laws. We may also use closed circuit television and other security measures at our properties that may capture or record images of guests and visitors in public areas, as well as information related to your location while on our properties (via keycards and other technologies). We may also use closed-circuit television and other technologies that record sound or video for the protection of our staff, guests and visitors to our properties where permitted by law. In addition, we may collect personal information in connection with on-property services, such as concierge services, spas, activities, child care services and equipment rental.</p>
                            <p>Social Media: If you choose to participate in AHR Sponsored social media activities or offerings, we may collect certain information from your social media account consistent with your settings within the social media service, such as location, check-ins, activities, interests, photos, videos, status updates and friend list. We may also allow you to enter competitions to provide photos, videos relating to your stay with us, which you may share with your connections on social media for votes, shared offers or other promotions.</p>
                            <p>In addition to the information we collect from you directly, we may also infer or collect information about you based on the information you provide to us or from information we collect or infer from any other sources.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>application of personal information</h6>
                            <p>We use captured personal information in multiple ways to personalise the services you request and expect from Atmosphere Hotels & Resorts, conduct direct marketing and sales promotions and as set forth below in more detail. We will collect your consent prior to processing your data where required by applicable law.</p>
                            <p>We are obligated to collect certain data, including your name, address, payment information, and, in certain countries, travel document information, in order to process your reservation. Failure to provide this information will result in our inability to process your reservation.</p>
                            <p>Marketing and Communications: Where permitted or reasonably applicable, we may use your personal information to provide or offer you newsletters, promotions and featured specials, as well as other marketing messages in accordance with any communications preferences you have expressed. We use your information to provide in-stay messaging, account alerts, and reservation confirmations; to send you marketing messages; to conduct surveys and other activities. We may provide these communications via email, postal mail, online advertising, social media, telephone, text message, push notifications, messaging applications, in-room television and other means. With your consent, we also use user-generated content from social media services to deliver display advertising or on our website and apps.</p>
                            <p>Service Enhancements: We may use your personal information to improve Atmosphere Hotels & Resort’s services and to ensure that our site, products, and services are of interest to you. We also use your personal information to provide you with the expected level of hospitality in-room and throughout our properties. Data Accuracy, Analytics and Personalization: We may aggregate your personal information with data from third-party sources for purposes of keeping information up to date and analytics. We also rely on information from third parties in order to provide better and more personalized service. For example, if you connect your social media services or other accounts to our services, we may use this information to make your experiences with us more personal.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>distribute personal information</h6>
                            <p>In order to offer you the expected level of hospitality and to provide you with the best level of service, we may share your personal information among our service providers, and other third parties as set forth in detail below:</p>
                            <p><span className='policy-text-color'>On-property Services:</span> We may share personal information with third-party providers of on-property services such as concierge services, WiFi access providers, transport service providers, spa treatments, diving and watersports centers, golf, or dining experiences.</p>
                            <p><span className='policy-text-color'>Service Providers:</span> We rely on third parties to provide services and products on our behalf and may share your personal information with them as appropriate. Generally, our service providers are contractually obligated to protect your personal information and may not otherwise use or share your personal information, except as may be required by law. However, our fraud detection service providers may use, but not share, your personal information for fraud detection purposes. We may use service providers to communicate news and deliver promotional and transactional materials to you on our behalf, including personalized online and mobile advertising in accordance with your preferences and applicable law. Please see our Policy on Cookies and Other Technologies for more information. Atmosphere Hotels & Resorts will only work with parties that offer a method to opt-out of such advertising. We may also share information with service providers to allow you to create itineraries by selecting sites, activities, and restaurants from lists that we have personalized for you based on your preferences and third-party data.</p>
                            <p>Other: In addition, Atmosphere Hotels & Resorts may disclose personal information in order to:</p>
                            <p>Comply with applicable laws.</p>
                            <p>Respond to governmental inquiries or requests from public authorities.</p>
                            <p>Comply with valid legal process.</p>
                            <p>Comply with a court order or order from a Government agency.</p>
                            <p>Protect the rights, privacy, safety or property of AHR, site visitors, guests, employees or the public.</p>
                            <p>Permit us to pursue available remedies or limit the damages that we may sustain.</p>
                            <p>Enforce our websites’ terms and conditions.</p>
                            <p>Respond to an emergency.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>other information</h6>
                            <p>When you visit and interact with Atmosphere Hotels & Resorts websites and apps, we collect other information that does not directly identify you about your use of the site, such as a catalog of the site pages you visit, and the number of visits to our sites (“Other Information”). We use Other Information, as well as data received from third parties, to deliver you email, online (on our sites and other sites) and mobile advertisements.</p>
                            <p>We use cookies and other technologies (such as “pixel tags,” “web beacons,” “clear GIFs”, links in emails, JavaScript, device IDs assigned by Google or Apple, or similar technologies) to collect this information. If you want to remove or block Cookies from your device at any time, you can update your browser settings (consult your browser’s “help” menu to learn how to remove or block Cookies). Atmosphere Core is not responsible for your browser settings. You can find good and simple instructions on how to manage Cookies on the different types of web browsers at www.allaboutcookies.org</p>
                            <p>We may use information we have collected and aggregated, or anonymized personal information received from third parties, to understand more about our users, customers or clientele (for example, we may use aggregated information to calculate the percentage of our users who have a particular telephone area code). This includes demographic data, such as date of birth, gender and marital status, inferred commercial interests, such as favorite products or hobbies, and other information we may collect from you or from third parties.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>sensitive information</h6>
                            <p>The term “sensitive information” refers to information related to your racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health, sex life or sexual orientation, genetic information, criminal background, and any biometric data used for the purpose of unique identification. In some jurisdictions, mobile phone numbers, location data, and information contained on identity documents also are considered sensitive information.</p>
                            <p>We do not generally collect sensitive information unless it is volunteered by you or unless we are required to do so pursuant to applicable laws or regulations. We may use health data provided by you to serve you better and meet your particular needs (for example, the provision of disability access).</p>

                            <h6 className='condition-space mb-0 text-uppercase'>personal information from children</h6>
                            <p>We do not knowingly collect personal information from individuals under 18 years of age. As a parent or legal guardian, please do not to allow your children to submit personal information without your permission.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>links to third-party websites and services</h6>
                            <p>Our site may contain links to third parties’ websites. Please note that we are not responsible for the collection, use, maintenance, sharing, or disclosure of data and information by such third parties. If you provide information on and use third-party sites, the privacy policy and terms of service on those sites are applicable. We encourage you to read the privacy policies of websites that you visit before submitting personal information.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>protecting personal information</h6>
                            <p>Atmosphere Core will take reasonable measures to: (i) protect personal information from unauthorized access, disclosure, alteration or destruction, and (ii) keep personal information accurate and up-to-date as appropriate. Atmosphere Core maintains a PCI compliance program and an IT compliance program. In the event of a security incident, we will notify regulators and/or consumers as required by applicable laws or regulations.</p>
                            <p>We also seek to require our affiliates and service providers with whom we share personal information to exercise reasonable efforts to maintain the confidentiality of personal information about you. For online transactions, we use reasonable technological measures to protect the personal information that you transmit to us via our site. Unfortunately, however, no security system or system of transmitting data over the Internet can be guaranteed to be entirely secure.</p>
                            <p>For your own privacy protection, please do not send payment card numbers or any other confidential personal information to us via email.</p>
                            <p>We will not contact you by mobile/text messaging or email to ask for your confidential personal information or payment card details. We will only ask for payment card details by telephone when you are booking a reservation or promotional package. If you receive this type of request, you should not respond to it. We also ask that you please notify us at dataprotection@atmospherehotelsandresorts.com</p>

                            <h6 className='condition-space mb-0 text-uppercase'>changing and accessing your personal information</h6>
                            <p>To the extent required by applicable law, you may be able to request that we inform you about the personal information we maintain about you and, where appropriate, withdraw your consent for certain data processing activity and/or request that we update, correct, delete, and/or stop processing your personal information. We will make all required updates and changes within the time specified by applicable law and as required by law. When permitted by law, we may charge an appropriate fee to cover the costs of responding to the request. Such requests may be submitted in writing to dataprotection@atmospherehotelsandresorts.com or Atmosphere Core Data Protection Officer, 3rd Floor, Aage’, 12 Boduthakurufaanu Magu, Henveiru, Male’ 20094, Republic of Maldives. To protect your confidentiality, we can only respond to such requests to the email address that you have registered or otherwise provided to us.</p>
                            <p>In addition, in some circumstances based on applicable law, you may request that we cease sharing personal information about you with our business partners and service providers or that Atmosphere Core cease using personal information about you by contacting us using the email or mailing address above. We will seek to honor those requests consistently with applicable law.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>retaining personal information</h6>
                            <p>We retain personal information about you for the period necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by applicable law. We retain personal information collected in order to fulfill guest reservations for seven years after the stay is completed. We retain other personal information for shorter periods of time if possible and if permitted by law. We will destroy your personal information as early as practicable and in a way that the information may not be restored or reconstructed.</p>
                            <p>If printed on paper, the personal information will be destroyed in a secure manner, such as by cross-shredding or incinerating the paper documents or otherwise and, if saved in electronic form, the personal information will be destroyed by technical means to ensure the information may not be restored or reconstructed at a later time.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>choices – marketing communications</h6>
                            <p>If you have given us your contact information (mail address, fax number, email address or phone number), we may want to inform you in accordance with any preferences you have expressed, and with your consent where required, about our products and services or invite you to events via email, online advertising, social media, WeChat, WhatsApp, Telegram, telephone, text message (including SMS and MMS), push notifications, in-app alerts, postal mail, our customer service call center, and other means (including on-property messaging, such as your in-room television).</p>
                            <p>If you prefer not to receive email marketing materials from us, you may opt-out at any time by writing in to dataprotection@atmospherehotelsandresorts.com. Opt-out requests can take up to ten business days to be effective.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>privacy policy modifications</h6>
                            <p>We may modify this policy from time to time. When changes to this policy are made we will post a link to the revised Statement on the homepage of our site, and if you have registered for any of your products or services, will may also inform you though a communications channel that you have provided. Any changes to our policy will become effective upon posting of the revised policy on the site. Use of the site, any of our products and services, and/or providing consent to the updated Statement following such changes constitutes your acceptance of the revised policy then in effect.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>contact us</h6>
                            <p>If you have any questions about this policy or how Atmosphere Core processes your personal information, or if you wish to either provide a compliment or a complaint, please contact us by email at dataprotection@atmospherehotelsandresorts.com or by postal mail to Atmosphere Core Data Protection Officer, 3rd Floor, Aage’, 12 Boduthakurufaanu Magu, Henveiru, Male’ 20094, Republic of Maldives. We will respond within 30 days or sooner if practicable.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>contact us</h6>
                            <p>If you have any questions about this policy or how Atmosphere Core processes your personal information, or if you wish to either provide a compliment or a complaint, please contact us by email at dataprotection@atmospherehotelsandresorts.com or by postal mail to Atmosphere Core Data Protection Officer, 3rd Floor, Aage’, 12 Boduthakurufaanu Magu, Henveiru, Male’ 20094, Republic of Maldives. We will respond within 30 days or sooner if practicable.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>additional information residents in european economic area (eea)</h6>
                            <p>For individuals residing in the EEA, this section outlines certain additional information that Atmosphere Core are obligated to provide to you, as well as certain rights you have with respect to the processing of your personal information, pursuant to applicable laws.</p>

                        
                            <p><span className='policy-text-color'>Data Protection Officer:</span>For individuals residing in the EEA, this section outlines certain additional information that Atmosphere Core are obligated to provide to you, as well as certain rights you have with respect to the processing of your personal information, pursuant to applicable laws.</p>
                            <p>Attn: Data Protection Officer</p>
                            <p>Atmosphere Core</p>
                            <p>3rd Floor, Aage’</p>
                            <p>12 Boduthakurufaanu Magu</p>
                            <p>Henveiru</p>
                            <p>Male’ 20094</p>
                            <p>Republic of Maldives.</p>
                            <p>Purposes and Legal Basis (data subject consent & legal obligation) for Processing: Atmosphere Core Pvt Ltd (“AHR”) collects personal information for the purpose of your stay in our hotel properties</p>
                            <p>The legal bases for Atmosphere Core’ processing activities include processing such information as necessary or reasonably required to comply with our contractual obligations, compliance with our legal obligations, protecting the safety of our employees, guests and others, for our legitimate business interests, pursuant to your consent.</p>
                            <p>The particular legal basis (data subject consent & legal obligation) for the processing of your personal information is based on the purpose for which such information was provided or collected:</p>
                            <p>Surveys: Completion of surveys is voluntary – we process the information obtained from surveys on the basis of your consent and in furtherance of our business interests, including marketing, service improvements, and analytics.</p>

                            <h6>On-property Collection:</h6>
                            <p>When you make a reservation and when you stay at one of our hotel properties, we process your name, address, contact information, along with the details of your stay (arrival and departure day and time, vehicle information and information regarding others traveling or staying with you), on the basis of our contractual relationship with you. We also process such data for our business interests, including for marketing, service improvements and analytics and service personalization, as described in our Privacy Policy.</p>
                            <p>We collect certain additional personal information during registration/check-in at our properties (such as national ID or passport information), as necessary to comply with our legal obligations.</p>
                            <p>We use closed circuit television and other security measures at our properties that may capture or record images of guests and visitors in public areas, as well as information related to your location while on our properties (via keycards and other technologies) for the protection of our staff, guests and visitors to our properties.</p>
                            <p>We process personal information in connection with on-property services (such as concierge services, spas, activities, child care services and equipment rental), in order to provide the services to you and for our business interests including for marketing, service improvements and analytics and service personalization, as described in the Privacy Policy.</p>
                            <p><span className='policy-text-color'>Social Media:</span> Participation in Atmosphere Core’ sponsored social media activities and offerings is voluntary – we process information obtained from social media participation on the basis of your consent and in furtherance of our related business interests, including for marketing, service improvements, and analytics and service personalization, as described in the Privacy Policy.</p>
                            <p><span className='policy-text-color'>Direct Marketing:</span> We use your personal information to send you marketing messages on the basis of your consent. You may withdraw your consent for direct marketing communications at any time by contacting us at dataprotection@atmospherehotelsandresorts.com</p>
                            <p><span className='policy-text-color'>Retention:</span> We retain personal information about you for the time necessary or reasonably required to accomplish the purpose for which such information was collected, usually for the duration of any contractual relationship and for any period thereafter as legally required or permitted by applicable law. Our retention policies reflect applicable statute of limitation periods and legal requirements.</p>
                            <p><span className='policy-text-color'>Data Subject Rights:</span> Residents of the EEA have the following rights:</p>
                            <p>Data Subject Rights: Residents of the EEA have the following rights:</p>
                            <ConditionList listdata={listdata} />

                            <p>Ask us to confirm whether we are processing your personal information.</p>
                            <p>Receive information on how your data is processed.</p>
                            <p>Obtain a copy of your personal information.</p>
                            <p>Request that we update or correct your personal information.</p>
                            <p>Request that we delete personal information in certain circumstances.</p>
                            <p><span className='policy-text-color'>Submitting Requests:</span> Your requests may be submitted by accessing the Data Subject Rights Request Portal or in writing to dataprotection@atmospherehotelsandresorts.com or the Atmosphere Core Data Protection Officer, 3rd Floor, Aage’, 12 Boduthakurufaanu Magu, Henveiru, Male’ 20094, Republic of Maldives. You may also update your personal information as mentioned on the Privacy Policy.</p>
                            <p>We will respond to all such requests within 30 days of our receipt of the request, unless there are extenuating circumstances, in which event we may take up to 60 days to respond. We will inform you if we expect our response to take longer than 30 days. Please note, however, that certain personal information may be exempt from such rights pursuant to applicable data protection laws. In addition, we will not respond to any request unless we are able to appropriately verify the requester’s identity. We may charge you a reasonable fee for subsequent copies of data that you request.</p>
                            <p>If you have concerns about our data practices or the exercise of your rights, you may either contact AHR at dataprotection@atmospherehotelsandresorts.com , or the supervisory authority in the Member State of your residence.</p>
                            <p><span className='policy-text-color'>International Data Transfers:</span> We may transfer the personal information we collect about you pursuant to the purposes described in this Statement to countries that have not been found by the European Commission to provide adequate protection. In particular, we transfer your personal information to the Maldives.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>policy on cookies and other technologies</h6>
                            <p>This policy describes the different types of Cookies and Other Technologies that may be used in connection with the websites owned or controlled by Atmosphere Core.</p>
                            <p>We may update this Statement at any time. Any changes in this Statement will become effective when we make the revised Statement available on or through the Site, or when the updated policy is uploaded on the Site.</p>
                            <p>If you have any questions, please contact us by email at dataprotection@atmospherehotelsandresorts.com or by postal mail at Atmosphere Core Data Protection Officer, 3rd Floor, Aage’, 12 Boduthakurufaanu Magu, Henveiru, Male’ 20094, Republic of Maldives.</p>

                            <h6 className='condition-space mb-0 text-uppercase'>collection and application of other information</h6>
                            <p>Like most websites, our Site may use “Cookies” or “Other Technologies” (such as “pixel tags,” “web beacons,” “clear GIFs”, links in emails, JavaScript, device IDs assigned by Google or Apple, or similar technologies). Cookies and Other Technologies allow us and third parties to obtain information about your visits to the Site, including to analyze your visiting patterns. We use this information to process your reservations or requests and to deliver online and mobile advertisements, messages and content for us and others that are specific to your interests.</p>
                            <p>Cookies are small text files that are stored on your computer or mobile device (“device”) when you visit a website. Cookies alone cannot be used to disclose your individual identity to us, though in some cases we may tie Atmosphere Core or third-party Cookies to personal information you have provided to us or that we have otherwise obtained about you. Cookies may be used on the Site in order to improve your experience. For example we may use Cookies to:</p>
                            <p>Remember your user name and password for future visits so that log-in is easier and faster;</p>
                            <p>Remember your language and other preferences;</p>
                            <p>Ensure you obtain all requested information;</p>
                            <p>Provide a safe and secure service for online transactions;</p>
                            <p>Track your response to advertisements and website or app content for analysis and the number of times we send you the same advertisement;</p>
                            <p>Measure how many people use the Site, and how they use it, so that we may keep it running quickly and efficiently; and Help us and others deliver communications and content to you that are relevant and responsive to your interests and location.</p>
                            <p>Following are the different types of Cookies that may be used on the Site:</p>
                            <p>Essential Cookies. Essential Cookies are necessary for the Site to work and enable you to move or navigate around it and use its services and features. Disabling these Cookies may make the services and features unavailable.</p>
                            <p>Functional Cookies. We use Functional Cookies to save your settings on the Site—settings such as your language preference or booking information you have previously used when booking a hotel with us. We also use Functional Cookies to store such things as the last hotel you searched for, so that you can easily find it the next time you visit. Some Functional Cookies are essential to viewing maps or videos on our Site. We also use “Flash Cookies” for some of our animated content.</p>
                            <p>Session Cookies. These types of Cookies are stored only temporarily during a browsing session and are deleted from your device when you close the browser. We use Session Cookies to support the functionality of the Site and to understand your use of the Site—that is, which pages you visit, which links you use and how long you stay on each page.</p>
                            <p>Persistent Cookies: These types of Cookies are not deleted when you close your browser, but are saved on your device for a fixed period of time or until you delete them. Each time you visit the Site, the server that set the Cookie will recognize the persistent Cookie saved on your device. We and others use persistent Cookies to store your preferences, so that they are available for your next visit, to keep a more accurate account of how often you visit the Site, how often you return, how your use of the Site may vary over time and the effectiveness of advertising efforts.</p>
                            <p>Advertising Cookies allow us and other advertisers to show you the most relevant products, offers, and advertisements on the Site and third-party sites, or through emails or other message platforms. For example, some Advertising Cookies help our service providers and other advertisers select advertisements that are based on your interests, including those expressed or inferred by visits to our websites or apps or across other websites, online services, and apps over time. Others help prevent the same advertisement from continuously reappearing for you. These types of Cookies also help us provide you with content on the Site that is tailored to your interests and needs.</p>
                            <p>As noted below, some Analytics Cookies and Other Technologies are used in part to facilitate advertising.</p>
                            <p>Advertising Cookies also include Social Plug-In Cookies. Social Plug-In Cookies are used to share content from the Site with members and non-members of social media networks such as Facebook, Twitter, YouTube and Pinterest. These Cookies are usually set by the social networking provider, enabling such sharing to be smooth and seamless.</p> */}
            </div>
          </div>
        </div>
      </sectio>
    </>
  );
}

export default PrivacyPolicy;
