import React from 'react'
import PlanCard from './PlanCard'


function PlanCards({ planCardData = [] }) {
  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }

    const truncatedText = text.slice(0, maxLength);
    return truncatedText;
}

const truncateText = (text = "", length) => {
  return text?.length > +length ? text.slice(0, +length) + "..." : text;
};
  return (
    <>
    <div className='row'>
               {
          planCardData?.map((item, index) => {
            if (item.image) {
              if (item.image === "" || item.image === null) {
                return
              }
              return (

                <PlanCard
                  // key={index}
                  // showSlogan={false}
                  planImage={item.image}
                  imgAltTag={item.imgAltTag}
                  // sortDes={true}
                  // showCondtionText={true}
                  key={index}
                  // planCardTitle={item.heading}
                  // planSlogan={item.sheading}
                  showSlogan={false}
                  // planImage={imageCard}
                  sortDes={false}
                  // pointlist={pointList}
                  // planMeinDes={item.sheading}
                  // planConditionText={item.cardtc}
                  showCondtionText={false}



                />

              )
            }
            else {

              if (item.heading === ""|| item.cardDesc===null) {

                return 
              }
            

                if(item.cardDesc=== "" || item.cardDesc===null){
                return
              }
          
              // let pointList = item?.cardDesc?.slice(0, 540).split('\n')
              // pointList= truncateText(item?.cardDesc,540)
              // let isexceedinglimit=item?.cardDesc?.length>540;
              // const allDescText=item?.cardDesc?.split('\n');

              // const list = truncateText(pointList,5)
              // isexceedinglimit=item?.cardDesc?.length>40;
              const pointList =item?.cardDesc?.split('\n');
              
            
              return (

                <PlanCard
                  key={index}
                  planCardTitle={item.heading}
                  planSlogan={item.sheading}
                  showSlogan={true}
                  // planImage={imageCard}
                  sortDes={true}
                  pointlist={pointList}
                  // isexceedinglimit={isexceedinglimit}
                 
                  // planMeinDes={item.sheading}
                  planConditionText={item.cardtc}
                  showCondtionText={true}
                  // allDescText={allDescText}
                />

              )
            }
          })
        }
      </div>
    </>
  )
}

export default PlanCards